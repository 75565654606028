const brand = {
    starter: {
        name: 'Lucid',
        desc: ' Lucid',
        prefix: '',
        footerText: `Lucid - All Rights Reserved PerformVu Inc. ${new Date().getFullYear()}`,
        logoText: ' Theme',
        projectName: 'Lucid Project',
        url: '',
        img: '/static/images/starter-logo.png',
        notifMsg:
            'Donec sit amet nulla sed arcu pulvinar ultricies commodo id ligula.',
    },
};

export default brand;
